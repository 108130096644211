import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Accordion from '../../../../components/Accordion';
import MyHealthEmployeeHealthProgramContent from '../MyHealthEmployeeHealthProgramContent';

const MyHealthEmployeeVoucherAccordion = ({
  toggle,
  handleInfo,
  handleModal,
  isOpen,
  voucherCoupon
}) => {
  const intl = useIntl();

  const firstCardDescription = intl.formatMessage({
    id: 'my-health.copy-voucher-code-description'
  });
  const secondCardDescription = intl.formatMessage({
    id: 'my-health.select-product-apply-voucher-description'
  });

  const productCardsData = [
    {
      title: (
        <FormattedMessage
          id='my-health.voucher-copy-title'
          defaultMessage='Step 1: Copy your personal voucher code'
        />
      ),
      price: null,
      description: firstCardDescription,
      cta: null,
      testType: '',
      iconUrl: null,
      copyableCode: voucherCoupon?.code
    },
    {
      title: (
        <FormattedMessage
          id='my-health.selec-product-apply-voucher'
          defaultMessage='Step 2: Select product and apply voucher code'
        />
      ),
      price: null,
      description: secondCardDescription,
      cta: {
        url: 'https://yourself.health/pages/lab-tests/',
        text: (
          <FormattedMessage
            id='my-health.explore-products'
            defaultMessage='Explore products'
          />
        ),
        type: 'link'
      },
      testType: '',
      iconUrl: null
    }
  ];

  return (
    <Accordion
      type={
        <FormattedMessage
          id='my-health.additional-benefits'
          defaultMessage='Additional benefits'
        />
      }
      title={
        <FormattedMessage
          id='my-health.voucher-title'
          defaultMessage='Voucher for additional products & services'
        />
      }
      isFirstAccordionOpen={isOpen}
      onToggle={toggle}
      content={
        <MyHealthEmployeeHealthProgramContent
          description={
            <FormattedMessage
              id='my-health.voucher-description'
              defaultMessage='Through your employer, you have also received a voucher of CHF100 which you can apply towards any of our products and services.'
            />
          }
          productCards={productCardsData}
          handleInfo={handleInfo}
          handleModal={handleModal}
        />
      }
    />
  );
};

MyHealthEmployeeVoucherAccordion.propTypes = {
  toggle: PropTypes.func,
  handleInfo: PropTypes.func,
  handleModal: PropTypes.func,
  isOpen: PropTypes.bool,
  voucherCoupon: PropTypes.object
};

export default MyHealthEmployeeVoucherAccordion;
