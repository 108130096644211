import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Heading4 } from '../../../components/Typography';
import Divider from '../../../components/Divider';
import MyHealthEmployeeInformationAccordion from './MyHealthEmployeeInformationAccordion';
import MyHealthEmployeeTestsAccordion from './MyHealthEmployeeTestsAccordion';
import MyHealthEmployeeKlenicoAccordion from './MyHealthEmployeeKlenicoAccordion';
import MyHealthEmployeeDiscountAccordion from './MyHealthEmployeeDiscountAccordion';
import MyHealthEmployeeVoucherAccordion from './MyHealthEmployeeVoucherAccordion';

import './index.scss';

const MyHealthEmployeeHealthProgram = ({
  employeeBenefits,
  handleInfo,
  handleModal
}) => {
  const { companyData, benefitCoupons } = employeeBenefits;
  const [openAccordionIndex, setOpenAccordionIndex] = useState(0);
  const klenicoCoupon =
    benefitCoupons && benefitCoupons.find(({ type }) => type === 'klenico');
  const discountCoupon =
    benefitCoupons && benefitCoupons.find(({ type }) => type === 'discount');
  const voucherCoupon =
    benefitCoupons && benefitCoupons.find(({ type }) => type === 'voucher');

  return (
    <div>
      <Divider
        title={
          <FormattedMessage
            id='my-health.employee-health-program'
            defaultMessage='Employee health program'
          />
        }
      />

      <Heading4 className='my-health-employee-health-program__description'>
        <FormattedMessage
          id='my-health.discover-health-benefits'
          defaultMessage='Discover the health benefits provided to you by {companyName}'
          values={{ companyName: companyData.name }}
        />
      </Heading4>

      <div className='my-health-employee-health-program__accordion'>
        <MyHealthEmployeeInformationAccordion
          toggle={() => setOpenAccordionIndex(0)}
          isOpen={openAccordionIndex === 0}
          handleInfo={handleInfo}
          handleModal={handleModal}
        />

        {employeeBenefits.benefitTests &&
          employeeBenefits.benefitTests.length > 0 && (
            <MyHealthEmployeeTestsAccordion
              toggle={() => setOpenAccordionIndex(1)}
              isOpen={openAccordionIndex === 1}
              handleInfo={handleInfo}
              handleModal={handleModal}
              tests={employeeBenefits.benefitTests}
            />
          )}

        {klenicoCoupon && (
          <MyHealthEmployeeKlenicoAccordion
            toggle={() => setOpenAccordionIndex(2)}
            isOpen={openAccordionIndex === 2}
            handleInfo={handleInfo}
            handleModal={handleModal}
            klenicoCoupon={klenicoCoupon}
          />
        )}

        {/* TODO: Implement check if coupon with type=== discount is received */}
        {voucherCoupon ? (
          <MyHealthEmployeeVoucherAccordion
            toggle={() => setOpenAccordionIndex(3)}
            isOpen={openAccordionIndex === 3}
            handleInfo={handleInfo}
            handleModal={handleModal}
            voucherCoupon={voucherCoupon}
          />
        ) : (
          <MyHealthEmployeeDiscountAccordion
            toggle={() => setOpenAccordionIndex(3)}
            isOpen={openAccordionIndex === 3}
            handleInfo={handleInfo}
            handleModal={handleModal}
          />
        )}

        {discountCoupon && <div>discount</div>}
      </div>
    </div>
  );
};

MyHealthEmployeeHealthProgram.propTypes = {
  employeeBenefits: PropTypes.object,
  handleInfo: PropTypes.func,
  handleModal: PropTypes.func
};

export default MyHealthEmployeeHealthProgram;
