import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTestResults } from '../../actions/testResult';
import { getDashboardData } from '../../actions/dashboard';

import TestResultsContent from '../../components/TestResultsContent';
import TestResultsImmunotherapy from '../../components/TestResultsImmunotherapy';
import TestResultsMainHeader from '../../../components/TestResultsMainHeader';

import './index.scss';

const TestResults = ({
  dispatch,
  data,
  error,
  loading,
  lang,
  changeLanguage
}) => {
  const { testCode } = useParams();

  useEffect(() => {
    if (lang === 'it' || lang === 'fr') {
      changeLanguage('en');
    } else {
      dispatch(getTestResults(testCode, lang));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, testCode, lang]);

  // If this test results were never viewed, we need to get the fresh dashboard data
  // because we need number of not viewed results for bubble in the header
  useEffect(() => {
    if (data && !data.resultsViewed) {
      dispatch(getDashboardData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) return <div className='test-results__loader'></div>;

  return (
    <div className='test-results'>
      <TestResultsMainHeader
        availableLanguages={['en', 'de']}
        defaultLanguage={lang}
        onChangeLanguage={changeLanguage}
        dispatch={dispatch}
        className='test-results__header'
        logoClassName='test-results__logo'
      />
      {data && data.type !== 'allergyCheckForImmunotherapy' && !error && (
        <TestResultsContent data={data} language={lang} />
      )}

      {data && data.type === 'allergyCheckForImmunotherapy' && !error && (
        <TestResultsImmunotherapy data={data} language={lang} />
      )}

      {/*We have older version, so I commented this new one out, so it won't overlap with older version */}
      {/* {error && <ContactService />} */}
    </div>
  );
};

TestResults.propTypes = {
  dispatch: PropTypes.func,
  data: PropTypes.object,
  error: PropTypes.object,
  loading: PropTypes.bool,
  lang: PropTypes.string,
  changeLanguage: PropTypes.func
};

const mapStateToProps = state => ({
  data: state.testResult.get('data'),
  error: state.testResult.get('error'),
  loading: state.testResult.get('loading')
});

export default connect(mapStateToProps)(TestResults);
