import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as Logo } from '../../../assets/svg/logo.svg';
import Background from '../../components/Background';

import './index.scss';

const Error = () => {
  const siteHref = 'https://yourself.health/';

  return (
    <div className='error'>
      <Background>
        <div className='error__header'>
          <a className='error__logo-wrapper' href={siteHref}>
            <Logo className='error__logo' />
          </a>
        </div>
        <div className='error__text'>
          <FormattedMessage
            id='error.contact-text'
            defaultMessage="It's not you, it's us. Please contact our "
          />{' '}
          <a
            className='error__customer-service-link'
            href='https://yourself.health/pages/service'
          >
            <FormattedMessage
              id='error.customer-service'
              defaultMessage='Customer service'
            />
          </a>
        </div>
      </Background>
    </div>
  );
};

export default Error;
